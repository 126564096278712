import React from 'react'
import { IonButton, IonItem, IonInput/*,isPlatform*/ } from '@ionic/react'
import { connect } from 'react-redux'
import Loading from '../../components/spinner'
import Layout from '../../components/layout'
import Clickup from '../../components/clickup'
import Modal from '../../components/modal'
import { Title, Spacer, NormalText, SmallText, FieldError } from '../../components/common'
import { validateForm, isDefined } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import { sendVoucherCode } from '../../store/actions'
import QRCode from 'qrcode-react'
import moment from '../../lib/moment'
import './index.css'
import NoDataSecond from '../../components/noDataSecond'
// import voucherDummyImg from '../../assets/images/dashboard-03.jpg'

// temporary dummy array
// const dummyVouchers = [
// 	{
// 		id: 'voucher-1',
// 		title: '£5',
// 		subtitle: 'off any meal',
// 		stamp: 'food-1',
// 		className: 'green',
// 		heading: 'Coffee Stamp Reward',
// 		subheading: 'Loyalty Card Free Coffee Reward',
// 		expiration: '03/09/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-2',
// 		title: '25% off',
// 		subtitle: 'all day tuesday',
// 		stamp: 'food-1',
// 		className: 'red',
// 		heading: 'All Day Tuesday Special',
// 		subheading: '25% off Coupon',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	},
// 	{
// 		id: 'voucher-3',
// 		title: 'double',
// 		titleSecondary: 'stamps',
// 		description: 'Monday Lunch between 11.30 - 14.30',
// 		stamp: 'food-1',
// 		className: 'black default-font',
// 		heading: 'Double Stamp Reward',
// 		subheading: 'Loyalty Card Reward',
// 		expiration: '03/05/20',
// 		details: 'You received this voucher as part of the ... blah, blah, truc, truc ...',
// 		qr: '??/assets/images/qr-dummy.png'
// 	}
// ]

// const VoucherTitle = ({ children }) => (
// 	<div className="voucher-title ellipsis">{ children }</div>
// )
// const VoucherSubtitle = ({ children }) => (
// 	<div className="voucher-subtitle ellipsis">{ children }</div>
// )
// const VoucherDescription = ({ children }) => (
// 	<div className="voucher-description">{ children }</div>
// )


const VoucherItem = ({ reward, id, className, __, action }) => {
	return (
		<div className={'voucher' + (className ? ' ' + className : '')}>
			{/* <div className="voucher-content">  */}
			{/* <div className="voucher-spacer"/> */}
			{/* <div className="voucher-info"> */}
			{/* { reward.name ? (
					<VoucherTitle>{ reward.name }</VoucherTitle>
					) : null }
					{ reward.small_print ? (
					<VoucherTitle>{ reward.small_print }</VoucherTitle>
					) : null }
					{ subtitle ? (
					<VoucherSubtitle>{ subtitle }</VoucherSubtitle>
					) : null }
					{ description ? (
					<VoucherDescription>{ description }</VoucherDescription>
					) : null } */}
			{/* </div> */}
			{/* <div className={ 'voucher-stamp' + (stamp ? ' ' + stamp : '')}/> */}
			{/* <div className="voucher-spacer"/>  */}
			{/* </div> */}
			<img alt="" src={ reward.image } width="100%"/>
			{ action ?
				<div className="voucher-bar">
					<div className="ellipsis">
						<p className="ellipsis">{ __(reward.name)}</p>
					</div>
					<div>
						<IonButton fill="outline" size="small" onClick={() => action(id)}>{ __('View')}</IonButton>
					</div>
				</div>
				: null }
		</div>
	)
}

class Vouchers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			voucherCode: '',
			selectedVoucher: null,
			formErrors: {
				voucherCode: ''
			}
		}
		this.formConfig = {
			voucherCode: { type: 'text', required: true }
		}
	}

	componentDidMount() {
		if (isDefined(this.props.location.state) && isDefined(this.props.location.state.openVoucherModal)) {
			setTimeout(() => {
				this.viewVoucher(this.props.location.state.openVoucherModal)
			}, 500)
		}
	}

	viewVoucher = (id) => {
		const { vouchers } = this.props
		this.setState({ selectedVoucher: vouchers.find((v) => v.id === id) })
	}

	redeemVoucher = () => {
		const { voucherCode } = this.state
		if (voucherCode !== '') {
			//console.log('Redeem voucher, code: "' + this.state.voucherCode + '"')
		}
	}

	clearFields = () => {
		this.setState({ voucherCode: '' })
	}

	sendCode = () => {
		const { dispatch, __ } = this.props
		let formErrors = validateForm(this.formConfig, this.state, __)
		this.setState({ formErrors })
		if (Object.keys(formErrors).length === 0) {
			const { voucherCode } = this.state
			const data = { code: voucherCode.toUpperCase() }
			this.clearFields()
			dispatch(sendVoucherCode(data))
		}
	}

	render() {
		const { __, vouchers, qr_code } = this.props
		const { voucherCode, selectedVoucher } = this.state
		const clickupContent =
			<Clickup label={ __('Redeem Offer Code')} height="320">
				<div className="absolute-wrapper flex-row-wrapper">
					<div className="padded no-padding-bottom centered scrollable-y">
						<p className="no-margin-top">{ __('Enter a valid voucher code below to receive your reward')}</p>
						<div className="lefted padded no-padding-bottom voucher-item">
							<IonItem style={{ '--min-height': '32px' }}>
								<IonInput value={ voucherCode } onIonChange={(e) => this.setState({ voucherCode: e.target.value })} placeholder={ __('Voucher code')}/>
								<FieldError className="field-error" value={ __(this.state.formErrors.voucherCode)} />
							</IonItem>
						</div>
					</div>
					<div className="padded flex-min">
						<IonButton disabled={ voucherCode === '' } color="secondary" expand="block" onClick={ this.sendCode }>{ __('Submit')}</IonButton>
					</div>
				</div>
			</Clickup>
		return (
			<Loading transparent>
				<Layout headerTitle={ __('Vouchers')} scrollY={false}>
					<div className="absolute-content flex-row-wrapper">
						<div className="flex-min">
							<Title>{ __('Voucher Wallet')}</Title>
							<Spacer size={1}/>
						</div>
						<div className="scrollable-y">
							{ !vouchers || vouchers.length === 0 ? <NoDataSecond label="Your vouchers will appear here"/> : null }
							{ vouchers && vouchers.map((voucher, index) => {
								return (
									<VoucherItem key={ 'vc-' + index } { ...voucher } __={ __ } action={ this.viewVoucher }/>
								)
							})}
							<Spacer size={2}/>
						</div>
						<div className="flex-min">
							{clickupContent}
						</div>
					</div>
					{/* {!isPlatform('ios') ? clickupContent : null} */}
				</Layout>
				{/* {isPlatform('ios') ? clickupContent : null} */}
				<Modal
					className="voucher-modal"
					isOpen={ selectedVoucher !== null }
					onDidDismiss={() => this.setState({ selectedVoucher: null })}
					title={ __('Voucher Info')}>
					{ selectedVoucher && selectedVoucher.reward ?
						<div className="centered">
							<img alt="" src={ selectedVoucher.reward.image } width="70%" />
							<Spacer size={2} />
							<div className="voucher-modal-content">
								{ selectedVoucher.reward.name ?
									<>
										<NormalText className="uppercase bold">{ selectedVoucher.reward.name }</NormalText>
										<Spacer/>
									</>
									: null }
								{ selectedVoucher.reward.small_print ?
									<div><NormalText>{ selectedVoucher.reward.small_print }</NormalText></div>
									: null }
								{ selectedVoucher.reward.expiry_date ?
									<div><NormalText>{ __('Expires:')} { moment(selectedVoucher.reward.expiry_date, 'YYYY/MM/DD').format('DD/MM/YY')}</NormalText></div>
									: null }
								<Spacer size={1} />
								<div><NormalText>{ __('Terms & Conditions')}</NormalText></div>
								<Spacer size={1} />
								{ selectedVoucher.reward.description ?
									<div><SmallText>{__(selectedVoucher.reward.description)}</SmallText></div>
									: null }
								<>
									<Spacer/>
									{isDefined(qr_code) ?
										<div className="qr-holder">
											<QRCode value={qr_code} size={200} />
										</div>
										:
										<div className='noQrCode'><h5>{__('NO QR CODE')}</h5></div>
									}
									<Spacer/>
								</>
							</div>
						</div>
						: null }
				</Modal>
			</Loading>
		)
	}
}

const stateToProps = state => {
	return {
		vouchers: state.profile.vouchers || [],
		qr_code: state.profile.profile.qr_code
	}
}

export default connect(stateToProps)(withTranslation(Vouchers))
