import { GET_TRANSACTION_HISTORY, IMPORT_BASKET, LIST_PAYMENT_CARDS, ADD_PAYMENT_CARD, REMOVE_PAYMENT_CARD, CREATE_ORDER, SET_SCROLL_TOP, ADD_SCANNED_CARD, SET_ORDERS_PROP } from './constants'

export const getTransactionHistory = () => ({ type: GET_TRANSACTION_HISTORY })

export const importBasket = () => ({ type: IMPORT_BASKET })

export const getPaymentCards = () => ({ type: LIST_PAYMENT_CARDS })

export const addPaymentCard = (name, options = {}) => ({ type: ADD_PAYMENT_CARD, name, options })

export const removePaymentCard = (cardToken, options = {}) => ({ type: REMOVE_PAYMENT_CARD, cardToken, options })

export const createOrder = (paymentType = null) => ({ type: CREATE_ORDER, paymentType })

export const setScrollTop = (value = 0) => ({ type: SET_SCROLL_TOP, value })

export const addScannedCard = (name = '', payment_token = '', options = {}) => ({ type: ADD_SCANNED_CARD, name, options, payment_token })

export const setOrdersProp = (key, value) => ({ type: SET_ORDERS_PROP, key, value })
