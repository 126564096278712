import React from 'react'
import { IonButton, IonButtons, IonIcon, IonItem, IonLabel, IonInput } from '@ionic/react'
import { starOutline } from 'ionicons/icons'
import Loading from '../../components/spinner'
import { pencilOutline } from 'ionicons/icons'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Sectiontitle } from '../../components/common'
import './index.css'
import Basket from '../../lib/basket'
//import { makeKey } from '../../lib/utils'
import ValidateButton from '../../components/validateButton'
import { getConfig } from '../../appConfig'
import Modal from '../../components/modal'
import Incrementer from '../../components/incrementer'
import { connect } from 'react-redux'
import OrderContent from '../../components/orderContent'
import { setModal } from '../../store/actions'
import { forwardTo, validateProfileData, goBack, go, isWebConfig } from '../../lib/utils'

const { getRestauranName, getOrderDate, getOrderTime, changeItemQuantity, itemsCount, setMobile, getMobile, getTotal } = Basket

class OrderSummaryRaw extends React.Component {
	constructor(props) {
		super(props)
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile)
		}
		this.state = {
			quantityModal: null,
			allergenModalOpen: false,
			mobile: getMobile() || ''
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.profile.mobile !== prevProps.profile.mobile) {
			this.setState({ mobile: this.props.profile.mobile })
		}
	}

	handleInput = (key, val) => {
		this.setState({ [key]: val })
		setMobile(val)
	}

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2)
		} else {
			goBack()
		}
	}

	handleSubmit = () => {
		const { cards } = this.props
		// if (!isMinimumOrderTotalSatisfied()) {
		// 	// display toast with flag 'true'
		// 	isMinimumOrderTotalSatisfied(true)
		// 	return
		// }
		if (getTotal() === 0) {
			Basket.createOrder()
			return
		}
		if (cards && cards.length >= 1) {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				forwardTo('/checkout')
				setMobile(this.props.profile.mobile )
			} else {
				forwardTo('/contact-details')
			}
		} else {
			if (this.props.profile.mobile && this.props.profile.first_name) {
				setMobile(this.props.profile.mobile )
				forwardTo('/card-add')
			} else {
				forwardTo('/contact-details')
			}
		}
	}

	handleOrderItemClick = (item, index) => {
		this.setState({ quantityModal: { item, index }})
	}

	updateItemQuantity = () => {
		const { item, index } = this.state.quantityModal
		if (this.state.quantityModal) {
			changeItemQuantity(index, item.quantity)
			this.setState({ quantityModal: null })
		}
	}

	onIncrementerUpdate = newQuantity => {
		this.setState({ quantityModal: {
			...this.state.quantityModal,
			item: {
				...this.state.quantityModal,
				quantity: newQuantity
			}
		}})
	}

	translateWeekDays = (opening_times) => {
		const { __ } = this.props

		opening_times = opening_times.replace('Monday', __('Monday'))
		opening_times = opening_times.replace('monday', __('monday'))
		opening_times = opening_times.replace('Tuesday', __('Tuesday'))
		opening_times = opening_times.replace('tuesday', __('tuesday'))
		opening_times = opening_times.replace('Wednesday', __('Wednesday'))
		opening_times = opening_times.replace('wednesday', __('wednesday'))
		opening_times = opening_times.replace('Thursday', __('Thursday'))
		opening_times = opening_times.replace('thursday', __('thursday'))
		opening_times = opening_times.replace('Friday', __('Friday'))
		opening_times = opening_times.replace('friday', __('friday'))
		opening_times = opening_times.replace('Saturday', __('Saturday'))
		opening_times = opening_times.replace('saturday', __('saturday'))
		opening_times = opening_times.replace('Sunday', __('Sunday'))
		opening_times = opening_times.replace('sunday', __('sunday'))

		return opening_times
	}

	translateGetOrderDate = (getOrderDate) => {
		const splitOrderDate = getOrderDate().split(' ')
		const translatedSeveralDay = this.translateWeekDays(`${splitOrderDate[0]}`)
		const theWholeStringOfDate = `${translatedSeveralDay !== undefined ? translatedSeveralDay : '' } ${splitOrderDate[1] !== undefined ? splitOrderDate[1] : ''} ${splitOrderDate[2] !== undefined ? splitOrderDate[2] : ''}`
		return theWholeStringOfDate
	}

	render () {
		const { __, profile, dispatch, auth } = this.props
		const { quantityModal, mobile } = this.state
		const valid = validateProfileData(profile).isValid
		const contactLabelArrg = isWebConfig() ? { position: 'floating' } : { slot: 'start' }
		const appliedVouchers = Basket.getAppliedVocuher()
		
		return (
			<>
				<div className="absolute-content flex-row-wrapper">
					<div className="scrollable-y checkout">
						<Title>{ __('Order Summary')}</Title>
						<SmallText color="gray">
							{ __('Ordering for') } <strong>{ /* getOrderDate() */ this.translateGetOrderDate(getOrderDate) }</strong> <span style={{ display: 'inline-block' }}>@ { getOrderTime() }</span><br />
							{ __('from') } <strong>{ getRestauranName() }</strong>
						</SmallText>
						{/* <IonItem lines='full'>
							<IonLabel slot='start' className="ion-text-wrap">
								<Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
							</IonLabel>
							<IonInput onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
							</IonInput>
							<IonButtons slot='end' className='no-margin'>
								<IonButton color="gray" disabled={ value === '' } fill="clear" size="small" onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}>
									<IonIcon slot="icon-only" size='small' icon={ pencilOutline }/>
								</IonButton>
							</IonButtons>
						</IonItem> */}
						<IonItem className='contact-number' lines='full'>
							<div tabIndex="-1"></div>
							<IonLabel { ...contactLabelArrg } className="ion-text-wrap contact-number-label">
								<Sectiontitle className="no-margin">{__('Contact number')}</Sectiontitle>
							</IonLabel>
							<IonInput className='mobile-field' onIonChange={ e => this.handleInput('mobile', e.target.value) } clearInput required={ true } type="tel" pattern="tel" inputmode="tel" value={ mobile }>
								{
									mobile === '' ?
									// <IonButtons slot='end' className='no-margin'>
									// 	<IonButton color="gray" /*disabled={ value === '' }*/ fill="clear" size="small" /*onTouchStart={() => this.togglePass(true)} onTouchEnd={() => this.togglePass(false)} onMouseDown={() => this.togglePass(true)} onMouseUp={() => this.togglePass(false)}*/>
									// 	</IonButton>
									// </IonButtons>
										<IonIcon icon={ pencilOutline } className="contact-number-icon" />
										: null
								}
							</IonInput>
						</IonItem>
						<div className="righted">
							<IonButton fill="clear" className="link" onClick={ () => forwardTo('/order') }><strong>+ { __('Add Items') }</strong></IonButton>
						</div>
						{ itemsCount() > 0 ?
							<OrderContent handleOrderItemClick={ this.handleOrderItemClick.bind(this) }/> :
							<IonItem lines="none">
								<div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>{ __('No items') }</div>
							</IonItem>
						}
						<div className='validate-content'>
							<ValidateButton />
							{!valid && getConfig().appType.hasEmailValidationEnabled ?
								<div className="verified-content">
									<SmallText color='grey'>{__('You can earn, but not redeem points until your account is verified')}</SmallText>
								</div> : null
							}
						</div>
					</div>
					<div className="flex-min">
						{auth && auth.loggedIn ? <><IonButton className={`redeem-points-btn ${itemsCount() === 0 || !valid ? 'disabled' : ''}`} onClick={ () => {
							if (itemsCount() === 0 || !valid) {
								dispatch(setModal('isVerfiedModalOpen', true))
							} else {
								forwardTo('/apply-vouchers')
							}}} expand="block" fill="outline" color="gray">
							{appliedVouchers && appliedVouchers.length > 0 ? __('Replace Voucher') : __('Apply Vouchers')}
						</IonButton>
						<IonButton disabled={ itemsCount() === 0 || !valid } onClick={ () => forwardTo('/apply-points') } expand="block" fill="outline" color="gray">
							<IonIcon slot="start" icon={ starOutline } />
							{ __('Redeem Points') }
						</IonButton></> : null}
						<IonButton disabled={ itemsCount() === 0 } onClick={ this.handleSubmit } expand="block">{ __('Checkout') }</IonButton>
					</div>
				</div>

				<Modal
					title={ __('Change quantity') }
					action={ this.updateItemQuantity }
					actionLabel={ __('Change')}
					isOpen={ !!quantityModal }
					onDidDismiss={ () => this.setState({ quantityModal: null }) }>
					{ quantityModal && quantityModal.item ?
						<Incrementer allowNegative={ false } quantity={ quantityModal.item.quantity } onUpdate={ this.onIncrementerUpdate }/>
						: null }
				</Modal>
			</>
		)
	}
}

const mapStateToProps = store => {
	return {
		basketUpdated: store.orders.basketUpdated,
		profile: store.profile.profile,
		auth: store.profile.auth,
		cards: store.orders.cards || []
	}
}

export const OrderSummary = connect(mapStateToProps)(withTranslation(OrderSummaryRaw))

class OrderSummaryWrapped extends React.Component {
	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.fromItemDetails) {
			// skip item details page when we going back from order summary
			go(-2)
		} else if (this.props.location && this.props.location.state && this.props.location.state.skipBackToThePreviousPage) {
			forwardTo('/order')
		} else {
			goBack()
		}
	}
	render() {
		const { __ } = this.props
		return (
			<Loading>
				<Layout headerTitle={ __('Order Summary') } backHandler = { this.backHandler }>
					<OrderSummary/>
				</Layout>
			</Loading>
		)
	}
}

export default withTranslation(OrderSummaryWrapped)
