import React, { Component } from 'react'
import { withTranslation } from '../../lib/translate.js'
import noVoucherWallet from '../../assets/images/no-voucher-wallet.svg'
import { Spacer } from '../../components/common'
import './index.css'

class NoDataSecond extends Component {
	render() {
		const { __, label, className, style } = this.props
		return (
			<div className="no-data-second-wrapper">
				<div className="no-data-second-topic">
					<img src={noVoucherWallet} alt="no-vouchers-wallet"/>
				</div>
				<Spacer size={1} />
				<p className={ className || '' } style={{ textAlign: 'center', ...style || {}}}>
					{ __(label) || 'No data'}
				</p>
			</div>

		)
	}
}

export default withTranslation(NoDataSecond)
