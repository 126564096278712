import React from 'react'
import { IonItem } from '@ionic/react'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer } from '../../components/common'
import Box, { BoxHeader } from '../../components/box'
import './index.css'
import { connect } from 'react-redux'
import { createNewBasketInstance } from '../../lib/basket'
import { forwardTo } from '../../lib/utils'
import OrderContent from '../../components/orderContent'
import AmountPaid from '../../components/amountPaid'
import Basket from '../../lib/basket'

class OrderCompleted extends React.Component {
	drawOrder = (order, lastOrder) => {
		const { __, cards } = this.props
		const { id } = order
		const basketInstance = createNewBasketInstance()
		basketInstance.recreateOrder(order)

		return (
			<>
				<div className="order-status">{ lastOrder && lastOrder.status ? __(lastOrder.status) : '' }</div>
				<Title>{ __(basketInstance.getOrderType()) } { __('Order') } #{ id }</Title>
				<SmallText color="gray">{ __('Payment Method') }: { basketInstance.formatPaymentMethod(cards, __) }</SmallText>
				<Spacer size={ 4 }/>
				<Box>
					<BoxHeader>
						<p className="light-text">
							<SmallText tag="strong">{ __('Order Location') }:</SmallText> <SmallText>{ order.restaurant_name }</SmallText><br />
						</p>
						<p className="light-text">
							<SmallText tag="strong">{ __('Order Time') }:</SmallText> <SmallText>{ basketInstance.formatOrderTime() }</SmallText><br />
						</p>
					</BoxHeader>
					<OrderContent basketInstance={ basketInstance } type="orderHistory"/>
					<AmountPaid order={ order } cards={ cards } />
				</Box>
			</>
		)
	}

	noOrder = () =>
		<IonItem lines="none">
			<div tabIndex="-1" className="sectiontitle" style={{ width: '100vh', textAlign: 'center' }}>{ this.props.__('No order') }</div>
		</IonItem>

	backHandler = () => {
		const { location } = this.props
		if (location && location.state && location.state.completedOrder) {
			forwardTo('/dashboard')
			forwardTo('/click-and-collect')
			Basket.reset()
		}
	}

	render () {
		const { __, lastOrder } = this.props
		const order = this.props.order || lastOrder

		return (
			<Loading>
				<Layout headerTitle={ __('Order Completed')} backHandler={ this.backHandler } scrollY={false}>
					<div className="absolute-content order-details">
						<div className="scrollable-y">
							{ order ? this.drawOrder(order, lastOrder) : this.noOrder() }
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	const orders = store.orders
	let lastOrder = null
	const orderHistory = orders.orderHistory
	if (orderHistory && orderHistory.length > 0) {
		lastOrder = orderHistory[0]
	}
	return {
		lastOrder,
		cards: orders.cards || []
	}
}

export default connect(mapStateToProps)(withTranslation(OrderCompleted))
