import React from 'react'
import { IonButton } from '@ionic/react'
import { connect } from 'react-redux'
import Loading from '../../components/spinner'
import { withTranslation } from '../../lib/translate'
import { sprintf, deepIsDefined, forwardTo, isDefined } from '../../lib/utils'
import Layout from '../../components/layout'
import { Title, SmallText, Spacer } from '../../components/common'
import Incrementer from '../../components/incrementer'
import Basket from '../../lib/basket'
import { getConfig } from '../../appConfig'
import BigNumber from '../../lib/bignumber'
import './index.css'

class ApplyPoints extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			points: this.getRedeemPointsMin()
		}
	}

	getRedeemPointsMin = () => getConfig().general.redeemPointsMin || 50

	componentDidMount() {
		const points = Basket.getAppliedPoints()
		if (points > 0) {
			this.setState({ points })
		}
	}

	onIncrementerUpdate = points => {
		// from min to 0
		if (points < this.state.points && points < this.getRedeemPointsMin()) {
			points = 0
		}
		// from 0 to min
		if (points > this.state.points && points < this.getRedeemPointsMin()) {
			points = this.getRedeemPointsMin()
		}
		this.setState({ points })
	}

	applyPoints = () => {
		Basket.applyPoints(this.state.points, this.props.availableBalance, () => {
			this.props.history.push('/order-summary')
		})
	}

	render () {
		const { __ } = this.props
		let availableBalance = this.props.availableBalance
		const { points } = this.state
		const basketTotalInCents = new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints()
		const formatedAmount = Basket._calculatePointsAppliedPrice(points, false, true)
		const step = getConfig().general.redeemPointsStep || 50
		let limit = 0
		if (!isDefined(availableBalance) && availableBalance === null) {
			availableBalance = 0
		} else {
			limit = parseInt(availableBalance / step) * step
		}

		// basket total: 5.2$ => 520 => 520/step(e.g. step=100)=5.2 => int(5.2) = 5 => 5 * step(e.g. step=100)
		// limit                    - represents available balance limit
		// pointsLimitBasedOnBasket - represents limit based on basket value
		// we will use lower value
		let pointsLimitBasedOnBasket = basketTotalInCents
		if (pointsLimitBasedOnBasket > 0) {
			if (basketTotalInCents <= availableBalance) {
				// if user have enough points to cover entire basket total then we should let him to go over step
				limit = basketTotalInCents
			} else {
				pointsLimitBasedOnBasket = parseInt(pointsLimitBasedOnBasket / step) * step
			}
		}

		return (
			<Loading>
				<Layout headerTitle={ __('Order Summary') }>
					<div className="absolute-content flex-row-wrapper">
						<div className="scrollable-y checkout">
							<Title>{ __('Redeem Points') }</Title>
							<SmallText color="gray">{ __('Exchange') + ' ' + step + ' ' + __('points for') + ' ' + Basket._calculatePointsAppliedPrice(step, false) + ' cents'}</SmallText>
							<Spacer size={ 4 }/>
							{/* <p>{ sprintf(__('You have') + ' %s ' + __('loyalty points available') + '.', limit + ' (' + Basket._calculatePointsAppliedPrice(limit, false) + ')') }<br />{ __('How much do you want to exchange') + '?' }</p> */}
							<p>{ sprintf(__('You have %s loyalty points available %s.'), availableBalance, Basket._calculatePointsAppliedPrice(availableBalance, false, true))} { __('How much do you want to exchange?')}</p>
							<Spacer size={ 2 }/>
							<Incrementer maxLimit={ limit < pointsLimitBasedOnBasket ? limit : pointsLimitBasedOnBasket } onUpdate={ this.onIncrementerUpdate } quantity={ points } step={ step } allowNegative={ false } unit={ __('Loyalty Points') } note={ __('Increase/decrease for') + ' ' + step + __(' points') }/>
						</div>
						<div className="flex-min">
							<SmallText tag="p" className="centered">
								{ sprintf(__('Would you like to redeem') + ' %s ' + __('points now') + '?', points + ' (' + formatedAmount + ')') }
							</SmallText>
							<IonButton
								disabled={ points === 0 ? false : points < this.getRedeemPointsMin() }
								expand="block"
								onClick={ this.applyPoints }
							>
								<strong>{ __('Apply Points') }</strong>&nbsp;{ formatedAmount }
							</IonButton>
							<div className="centered">
								<IonButton color="gray" fill="clear" className="link" onClick={ () => forwardTo('/order-summary') }>{ __('Cancel') }</IonButton>
							</div>
						</div>
					</div>
				</Layout>
			</Loading>
		)
	}
}

const mapStateToProps = store => {
	return {
		availableBalance: deepIsDefined(store, 'profile.profile.available_balance') ? store.profile.profile.available_balance : 0,
		basketUpadated: store.orders.basketUpdated
	}
}

export default connect(mapStateToProps)(withTranslation(ApplyPoints))
