import React, { Component } from 'react'
import { IonGrid, IonRow, IonCol, IonBadge } from '@ionic/react'
import { SmallText, Title, Spacer } from '../common'
import { makeKey, isDefined } from '../../lib/utils'
import { withTranslation } from '../../lib/translate'
import Basket from '../../lib/basket'
import './index.css'
import { removeVoucher, setModal } from '../../store/actions'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import Modal from '../../components/modal'

class OrderContent extends Component {

	drawSubItems = (basketItem = {}/*, basketItemIndex*/) => {
		const { selectedChoices, quantity } = basketItem
		const basketInstance = this.props.basketInstance || Basket
		const { profile } = this.props

		if ( selectedChoices && selectedChoices.length > 0 ) {
			return selectedChoices.map((choiceGroup, choiceGroupIndex) => {
				return (choiceGroup || []).map((choice, choiceIndex) => {
					const { sku } = choice
					return (
						<IonRow key={ makeKey(choiceGroupIndex, choiceIndex, sku) } /*onClick={ () => this.handleOrderItemClick(basketItem, basketItemIndex) }*/>
							<IonCol size="50px"></IonCol>
							<IonCol className="paddLR grow">{ quantity } x { Basket.getProductName(choice, profile) }</IonCol>
							<IonCol className="righted paddLR">{ basketInstance.calculateSubItemPrice(choice, quantity) !== 0 ? basketInstance._calculateSubItemPrice(choice, quantity) : null }</IonCol>
						</IonRow>
					)
				})
			})
		}
		return null
	}

	render() {
		const { handleOrderItemClick, __, history, profile, dispatch, isRemoveVoucherModalOpen } = this.props
		const basketInstance = this.props.basketInstance || Basket
		const type = this.props.type || 'order'
		const appliedPoints = basketInstance.getAppliedPoints()
		const appliedVoucher = basketInstance.getAppliedVocuher()
		let location = history && history.location && history.location.pathname === '/history-details' || history.location.pathname === '/order-completed' ? true : false

		return (
			<IonGrid className="order-content-wrapper paddLR">
				{
					basketInstance.getItems().map((basketItem, basketItemIndex) => {
						const { quantity, item, instructions } = basketItem
						return (
							<div key={ basketItemIndex } className="basket-item-wrapper">
								<IonRow key={ makeKey(basketItemIndex, item.sku) } onClick={ () => {
									if (handleOrderItemClick) {
										handleOrderItemClick(basketItem, basketItemIndex)
									}
								}}>
									<IonCol className="paddLR grow">{ quantity } x { Basket.getProductName(item, profile) }</IonCol>
									<IonCol className="righted paddLR">{ basketInstance.calculateItemPriceByIndex(basketItemIndex, false) > 0 ? basketInstance._calculateItemPriceByIndex(basketItemIndex, false) : null }</IonCol>
								</IonRow>
								{ this.drawSubItems(basketItem, basketItemIndex) }
								{instructions && instructions !== '' ?
									<IonRow>
										<IonCol><SmallText>{ __('Special Notes') }:</SmallText></IonCol>
										<IonCol className="righted instruction-grow"><SmallText>{ instructions }</SmallText></IonCol>
									</IonRow>
									: null
								}
							</div>
						)
					})
				}
				{ isDefined(appliedPoints) && appliedPoints > 0 ?
					<>
						<IonRow>
							<IonCol className="paddLR">
								<SmallText color="gray" className="uppercase paddLR">
									{ __('Subtotal') }
									<IonBadge className="small" slot="end">i</IonBadge>
								</SmallText>
							</IonCol>
							<IonCol className="righted paddLR">{ basketInstance._getSubTotal() }</IonCol>
						</IonRow>
						<IonRow className={`total-bordered-bottom ${ isDefined(appliedPoints) && appliedPoints > 0 ? 'total-bordered-top' : '' }`} onClick={ () => {
							if (type === 'order' && history) {
								history.push('/apply-points')
							}
						}}>
							<IonCol className="paddLR grow">{ appliedPoints } { __(' Points Redeem') }</IonCol>
							<IonCol className="righted paddLR">{ basketInstance._calculatePointsAppliedPrice(null, true) }</IonCol>
						</IonRow>

					</>
					: null
				}
				{
					appliedVoucher && appliedVoucher.length > 0 && appliedVoucher.map(voucher => {
						return (
							<div key={ voucher.id } >
								<Spacer size={ 1 } />
								<Title className='centered'>{ __('Vouchers')}</Title>
								<IonRow className={`${ appliedVoucher && appliedVoucher.length > 0 ? 'total-bordered-top' + ' total-bordered-bottom' : null }`} onClick={ () => location ? null : dispatch(setModal('isRemoveVoucherModalOpen', true)) }>
									<IonCol className="paddLR grow">{ __(voucher.reward.name) } </IonCol>
									<IonCol className="righted paddLR">{ basketInstance._calculateAppliedVocuhersPrice(true, null) }</IonCol>
								</IonRow>
								<Modal
									className='remove-apply-voucuher'
									noContent={true}
									title={ __('Do you want to remove voucher') + ' ?' }
									actionLabel={ __('Yes')}
									action = {() => dispatch(removeVoucher(voucher.id))}
									isOpen={ !!isRemoveVoucherModalOpen }
									onDidDismiss={ () => dispatch(setModal('isRemoveVoucherModalOpen', false)) }
								/>
							</div>
						)})
				}
				<IonRow>
					<IonCol className="paddLR">
						<SmallText color="gray" className="uppercase paddLR">{ __('Total') }</SmallText>
					</IonCol>
					<IonCol className="righted paddLR">{ basketInstance._getTotal(true) }</IonCol>
				</IonRow>
				{/* <IonRow>
					<IonCol>
						<SmallText color="gray" className="uppercase">
							{ type === 'order' ?
								<>
									{ __('Subtotal') }
									<IonBadge className="small" slot="end">i</IonBadge>
								</> : __('Total')
							}
						</SmallText>
					</IonCol>
					<IonCol className="righted">{ basketInstance._getTotal() }</IonCol>
				</IonRow> */}
			</IonGrid>
		)
	}
}

const mapStateToProps = store => {
	return {
		profile: store.profile.profile,
		isRemoveVoucherModalOpen: store.profile.isRemoveVoucherModalOpen
	}
}

export default connect(mapStateToProps)(withRouter(withTranslation(OrderContent)))
