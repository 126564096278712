import { SET_ORDERS_PROP, SET_SCROLL_TOP } from './constants'
import { isDefined } from '../../lib/utils'

let initialState = {
	history: [],
	basketUpdated: null,
	cards: [],
	orderHistory: [],
	scrollTop: 0
}

function reducer(state = initialState, action) {
	switch (action.type) {
	case SET_ORDERS_PROP:
		return { ...state, [action.key]: isDefined(action.merge) && action.merge ? { ...state[action.key], ...action.value } : action.value }
	case SET_SCROLL_TOP:
		return { ...state, scrollTop: action.value }
	default:
		return state
	}
}

export default reducer
